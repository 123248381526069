import {ref, reactive, toRefs, computed,defineComponent,onBeforeMount,onMounted,getCurrentInstance,nextTick} from 'vue';
import OrderUtil ,{IOrderDataObj} from './orderUtil';
export default defineComponent({
    name:'Order',
    props: {
        priceFlag: {type: Boolean,default: true},//是否展示单价
        disablePrice: {type: Boolean,default: false},//是否禁用单价
        sumAmountFlag: {type: Boolean,default: true},//是否展示总价
        numEditFlag: {type: Boolean,default: true},//是否可以编辑数量（新建订单的时候可以）
        pccFlag: {type: Boolean,default: true},//是否展示品牌、车型、车型公告号下拉框
        addBtnFlag: {type: Boolean,default: true},//是否展示添加产品按钮
        disableCustomer: {type: Boolean,default: true},//是否禁用客户信息
        customerDelivery: {type: Boolean,default: true},//是否禁用客户收货地址
        disablePcc: {type: Boolean,default: true},//是否禁用品牌、车型、车型公告号
        otherInfoFlag: {type: Boolean,default: true},//是否展示结算币种、当前汇率、预付款比例
        disableOtherInfo: {type: Boolean,default: false},//是否禁用结算币种、当前汇率、预付款比例
        showHis: {type: Boolean,default: false},//是否展示历史按钮
    },
    title:'订单',
    modelType:'card',
    fullscreen: true,
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IOrderDataObj=reactive<IOrderDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            otherParams:{
                loading:false,
                loading2:false,
                customerData:[],//客户名称下拉数据
                customerAccountData:[],//客户账号下拉数据
                customerDeliveryData:[],//收货人下拉数据

                picInfo:{//查看图片信息时，图片弹出框相关属性设置
                    dialogVisible:false,
                    picUrl:'',
                    picUrlList:[],
                },
                brandData:[],//品牌下拉数据
                cxData:[],//各个配置项车型下拉数据
                cxDataBak:[],//车型下拉数据（初次进入组件赋值）
                cxgghData:[],//各个配置项车型公告号下拉数据
                cxgghDataBak:[],//车型公告号下拉数据（初次进入组件赋值）
                orderData:{
                    processInfo:{result:'',shDesc:''},//审核信息
                    customer:{//客户信息
                        customerId:'',code:'',countryName:'',contact:'',contactAddress:'',
                        customerAccountId:'',
                        customerDeliveryId:'',deliveryContact:'',deliveryAddress:'',xhgk:''
                    },
                    otherInfo:{jsType:0,rate:'',percent:'',showUs:1},//结算类型、利率、首付比例、是否展示美元
                    sumInfo:{sumNum:0,sumUsd:0,sumUsdHis:[],sumRmb:0,sumRmbHis:[]},//汇总信息
                    cfgData:[//车型公告号根据车型的配置信息
                    /*{
                        pccInfo:{brandId:'',cxId:'',cxgghId:''},
                        itemChildren:[
                                     {
                                         header:{
                                             cxName:'',cxgghDetailId:'',cxggh:'',enDesc:'',zhDesc:'',price:0,//新建的时候由CxGghHelper的getCxgghOrderInfo返回，然后保存到订单jsonContent中
                                             priceHis:[],priceUsd:0,priceUsdHis:[],//pi发布的时候就会为订单添加这几个属性
                                             totalNum:0,//新建订单的时候，输入数量，自动为对象添加该属性然后保存到订单jsonContent中
                                             totalRmb:0,totalUsd:0//新建或修改pi的时候，输入单价，自动为对象添加这些属性然后保存到订单jsonContent中
                                         },
                                         children:[{color:'',num:'',picUrl:'',cpmId:''}]//新建的时候由CxGghHelper的getCxgghOrderInfo返回，然后保存到订单jsonContent中
                                     },
                                     {......}
                               ]
                     }*/
                    ]
                }
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new OrderUtil(proxy,dataObj,props,context);
        })
        onMounted(()=>{
            //外界会对orderData进行设值，然后根据orderData初始化页面。下方代码放到nextTick中，就能根据orderData把对应数据查询出来
            nextTick(async()=>{
                dataObj.otherParams.loading=true;
                await dataObj.utilInst.buildSelectData();//客户、品牌、所有车型、所有车型公告号、客户账户、收货地址下拉数据准备
                dataObj.utilInst.initCxCxgghData();//订单中每个子项的车型、车型公告号的下拉数据初始化
                dataObj.otherParams.loading=false;
            })
        })
        const setOrderData=(orderData:any)=>{
            dataObj.otherParams.orderData=orderData;
        }
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData
                }
                if('customerAccountId'==params.comboId){
                    return dataObj.otherParams.customerAccountData
                }
                if(params && 'customerDeliveryId'==params.comboId){
                    return dataObj.otherParams.customerDeliveryData
                }
                if(params && 'brandId'==params.comboId){
                    return dataObj.otherParams.brandData;
                }
                if(params && 'cxId'==params.comboId){
                    return dataObj.otherParams.cxData[params.index];
                }
                if(params && 'cxgghId'==params.comboId){
                    return dataObj.otherParams.cxgghData[params.index];
                }
                if(params && 'jsType'==params.comboId){
                    return [{value:0,label:'人民币'},{value:1,label:'美元'}]
                }
            }
        })
        //计算总数量
        const sumNum=computed(()=>{
            let sumNum:number=0;
            dataObj.otherParams.orderData.cfgData.forEach((item:any)=>item.itemChildren.forEach((item2:any)=>item2.children.forEach((item3:any)=>sumNum+=item3.num)))
            dataObj.otherParams.orderData.sumInfo.sumNum=sumNum;
            return sumNum;
        })
        //计算总美元
        const sumUsd=computed(()=>{
            let sumUsd:number=0;
            dataObj.otherParams.orderData.cfgData.forEach((item:any)=>item.itemChildren.forEach((item2:any)=>sumUsd+=item2.header.totalUsd))
            dataObj.otherParams.orderData.sumInfo.sumUsd=sumUsd.toFixed(2);
            return sumUsd.toFixed(2);
        })
        //计算总人民币
        const sumRmb=computed(()=>{
            let sumRmb:number=0;
            dataObj.otherParams.orderData.cfgData.forEach((item:any)=>item.itemChildren.forEach((item2:any)=>sumRmb+=item2.header.totalRmb))
            dataObj.otherParams.orderData.sumInfo.sumRmb=sumRmb.toFixed(2);
            return sumRmb.toFixed(2);
        })
        //计算某个车型公告号的某个明细项的总数量(index:第几个车型公告号；index1：车型公告号中第几个配置明细项--每个配置明细项中有很多颜色的车)-不会记录到orderData中
        const totalCxgghNum=computed(()=>{
            return (params:any) => {
                //直接返回下方这样不行，当不显示单价和金额的时候，header里面有可能还没有totalNum，因为totalNum是在计算单价和金额的时候赋值的，所以还是老实的按下方计算吧
                // return dataObj.otherParams.orderData.cfgData[params.index].itemChildren[params.index1].header.totalNum;
                let total:number=0;
                dataObj.otherParams.orderData.cfgData[params.index].itemChildren[params.index1].children.forEach((item:any)=>total+=item.num);
                return total;
            }
        })
        //计算某个车型公告号的某个明细项的总人民币(index:第几个车型公告号；index1：车型公告号中第几个配置明细项--每个配置明细项中有很多颜色的车)
        const totalRmb=computed(()=>{
            return (params:any) => {
                return dataObj.utilInst.calRowTotalAmount(Object.assign(params,{calTotalType:'rmb'}));
            }
        })
        //计算某个车型公告号的某个明细项的总美元(index:第几个车型公告号；index1：车型公告号中第几个配置明细项--每个配置明细项中有很多颜色的车)
        const totalUsd=computed(()=>{
            return (params:any) => {
                return dataObj.utilInst.calRowTotalAmount(Object.assign(params,{calTotalType:'usd'}));
            }
        })
        //计算人名币兑美元
        const priceUsd=computed(()=>{
            return (params:any) => {
                let result=params.price*dataObj.otherParams.orderData.otherInfo.rate;
                dataObj.otherParams.orderData.cfgData[params.index].itemChildren[params.index1].header.priceUsd=result;
                return result.toFixed(2);
            }
        })
        //计算美元兑人名币
        const priceRmb=computed(()=>{
            return (params:any) => {
                let result=params.priceUsd*dataObj.otherParams.orderData.otherInfo.rate;
                dataObj.otherParams.orderData.cfgData[params.index].itemChildren[params.index1].header.price=result;
                return result.toFixed(2);
            }
        })
        //英文大写
        const enBigAmount=computed(()=>{
            return utils.UtilPub.toEnBigAmount(sumUsd.value);
        })
        //中文大写
        const zhBigAmount=computed(()=>{
            // utils.UtilPub.toEnBigAmount(sumRmb.value);
            // return utils.UtilPub.toZhBigAmount(sumRmb.value);
            return utils.UtilPub.toEnBigAmount(sumRmb.value,1);
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:any,index:number,selectId:string)=>{
            if('customerId'==selectId){
                if(newValue){//客户名称下拉改变，有值，则重新设置相关项内容
                    dataObj.otherParams.orderData.customer.code=newValue.item.code;
                    dataObj.otherParams.orderData.customer.countryName=newValue.item.countryName;
                    dataObj.otherParams.orderData.customer.contact=newValue.item.contact;
                    dataObj.otherParams.orderData.customer.contactAddress=newValue.item.contactAddress;
                }else{//没有值，则清空相关项内容
                    dataObj.otherParams.orderData.customer.code='';
                    dataObj.otherParams.orderData.customer.countryName='';
                    dataObj.otherParams.orderData.customer.contact='';
                    dataObj.otherParams.orderData.customer.contactAddress='';
                }
                //不管有值没值，都要清空账号信息和收货人信息
                dataObj.otherParams.orderData.customer.customerDeliveryId='';
                dataObj.otherParams.orderData.customer.deliveryContact='';
                dataObj.otherParams.orderData.customer.deliveryAddress='';
                dataObj.otherParams.orderData.customer.xhgk='';

                dataObj.otherParams.orderData.customer.customerAccountId='';
                //重新加载账号和收货人信息构建其下拉数据
                await dataObj.utilInst.getCustomerDeliveryByCustomerId(newValue.value);
                await dataObj.utilInst.getCustomerAccountByCustomerId(newValue.value);
            }
            if('customerAccountId'==selectId){}
            if('customerDeliveryId'==selectId){
                if(newValue){
                    dataObj.otherParams.orderData.customer.deliveryContact=newValue.item.contact;
                    dataObj.otherParams.orderData.customer.deliveryAddress=newValue.item.address;
                    dataObj.otherParams.orderData.customer.xhgk=newValue.item.xhgk;
                }else{
                    dataObj.otherParams.orderData.customer.deliveryContact='';
                    dataObj.otherParams.orderData.customer.deliveryAddress='';
                    dataObj.otherParams.orderData.customer.xhgk='';
                }
            }

            if('brandId'==selectId){
                dataObj.otherParams.orderData.cfgData[index].pccInfo.cxId='';
                dataObj.otherParams.orderData.cfgData[index].pccInfo.cxgghId='';
                dataObj.otherParams.cxData[index]=await utils.OrderProviderApi.getAllCx({params:{brandId:newValue}});
            }
            if('cxId'==selectId){
                let countryId=dataObj.otherParams.orderData.customer.customerId.item.countryId;
                dataObj.otherParams.orderData.cfgData[index].pccInfo.cxgghId='';
                dataObj.otherParams.cxgghData[index]=await utils.OrderProviderApi.getAllCxggh({params:{cxId:newValue,countryId:countryId}});
            }
            if('cxgghId'==selectId){
                if(!dataObj.utilInst.checkCxgghDouble(newValue,index)){
                    proxy.$message({type:'warning',message:'该车型公告号已存在'});
                }else{
                    let countryId=dataObj.otherParams.orderData.customer.customerId.item.countryId;
                    dataObj.otherParams.loading2=true;
                    let res=await utils.OrderProviderApi.getCxgghOrderInfo({params:{cxgghId:newValue,countryId:countryId}});
                    if(res.result)dataObj.otherParams.orderData.cfgData[index].itemChildren=JSON.parse(res.resultData);
                    nextTick(()=>{
                        dataObj.otherParams.loading2=false;
                    })
                }
            }
            if('jsType'==selectId){
                if(1==newValue)dataObj.otherParams.orderData.otherInfo.showUs="1";
            }
        }
        //添加配置事件
        const addOrder=()=>{
            if(dataObj.otherParams.orderData.customer.customerId){
                dataObj.otherParams.orderData.cfgData.push({pccInfo:{brandId:'',cxId:'',cxgghId:''},itemChildren:[]});
                dataObj.otherParams.cxData[dataObj.otherParams.orderData.cfgData.length]=[];
                dataObj.otherParams.cxgghData[dataObj.otherParams.orderData.cfgData.length]=[];
            }else{
                proxy.$message('请选择客户名称');
            }
        }
        //去除配置事件
        const removeOrder=(index:number)=>{
            dataObj.otherParams.orderData.cfgData.splice(index,1);
        }
        //图片详情
        const showPic=(picUrl:string)=>{
            dataObj.otherParams.picInfo.picUrl=picUrl;
            dataObj.otherParams.picInfo.picUrlList=[picUrl];
            dataObj.otherParams.picInfo.dialogVisible=true;
        }
        //构建历史json数据
        const buildHis=(content:any)=>{
            //很奇怪，我重构代码之后，这些历史数据变为json字符串了，以前它就是一个json对象，所以需要判断一下类型
            return typeof content==='string'?JSON.parse(content):content;
        }
        return{
            ...toRefs(dataObj),proxy,sumNum,sumUsd,sumRmb,totalCxgghNum,totalRmb,totalUsd,priceUsd,priceRmb,enBigAmount,zhBigAmount,setOrderData,
            comboSelect,selectOnChange,addOrder,removeOrder,showPic,buildHis
        }
    }
});